import React from "react"

import Img from "../common/Img"
import LocalizedProductLink from "../common/LocalizedProductLink"
import RichText from "../common/RichText"
import ProductShortInfo from "../ProductShortInfo/ProductShortInfo"
import s from "./ProductSearchResult.module.scss"

export default function ProductSearchResult({
  imageUrl,
  kcal,
  locale,
  name,
  productGroupSlug,
  slug,
}) {
  return (
    <LocalizedProductLink
      className={s.productSearchResult}
      productGroupSlug={productGroupSlug}
      productSlug={slug}
    >
      <Img
        alt={name}
        className={s.image}
        objectFit="contain"
        objectPosition="center"
        placeholderHidden
        src={imageUrl}
      />
      <div>
        <h4 className={s.productName}>
          <RichText>{name}</RichText>
        </h4>
        <ProductShortInfo kcal={kcal} locale={locale} />
      </div>
    </LocalizedProductLink>
  )
}
