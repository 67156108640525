import { graphql, navigate } from "gatsby"
import React, { useEffect, useState } from "react"

import Hero, {
  colors,
  variations as heroVariations,
  titleColors,
} from "../components/Hero/Hero"
import Layout from "../components/Layout"
import PageSearchResult from "../components/Search/PageSearchResult"
import ProductGroupSearchResult from "../components/Search/ProductGroupSearchResult"
import ProductSearchResult from "../components/Search/ProductSearchResult"
import SearchBar, {
  variations as searchVariations,
} from "../components/SearchBar/SearchBar"
import SEO from "../components/seo"
import {
  selectPagesSearchIndex,
  selectProductGroups,
  selectProductGroupsSearchIndex,
  selectProductsSearchIndex,
  selectSearchPageContent,
  selectTranslations,
} from "../graphql/selectors"
import { isBrowser } from "../utils/env"
import s from "./search.module.scss"

const searchParamRegExp = /(([?&])q=)(.[^&:\\/\s]*)?/gm

export default function SearchPage(props) {
  const { data, pageContext, path, location } = props
  const { langCode, locale, localePath, pagePathsByLangCode } = pageContext
  const translations = selectTranslations(data)
  const { searchFieldPlaceholder } = translations.ui
  const { seo, hero } = selectSearchPageContent(data)
  const productsSearchIndex = selectProductsSearchIndex(data)
  const productGroupsSearchIndex = selectProductGroupsSearchIndex(data)
  const pagesSearchIndex = selectPagesSearchIndex(data)

  const queryFromUrl = isBrowser() && getSearchQueryFromUrl(location.href)
  const [query, setQuery] = useState(queryFromUrl || "")
  const [pagesResults, setPagesResults] = useState([])
  const [productGroupsResults, setProductGroupsResults] = useState([])
  const [productsResults, setProductsResults] = useState([])

  useEffect(() => {
    searchPages(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    const queryFromUrl =
      (isBrowser() && getSearchQueryFromUrl(location.href)) || ""

    if (query !== queryFromUrl) {
      setQuery(queryFromUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.href])

  return (
    <Layout
      className={s.searchPage}
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO {...seo} lang={langCode} />
      <Hero
        color={colors.crusta}
        title={hero.title}
        titleColor={titleColors.bright}
        variation={heroVariations.compact}
      >
        <SearchBar
          onChange={handleInputChange}
          placeholder={searchFieldPlaceholder}
          value={query}
          variation={searchVariations.hero}
        />
      </Hero>
      <div className={s.results}>
        {productGroupsResults.map((productGroup) => (
          <ProductGroupSearchResult
            {...productGroup}
            key={productGroup.id}
            query={query}
          />
        ))}
        {productsResults.map((product) => (
          <ProductSearchResult {...product} key={product.id} locale={locale} />
        ))}
        {pagesResults.map((page) => (
          <PageSearchResult
            description={getPageResultDescription(page)}
            key={page.id}
            pageId={page.id}
            query={query}
            title={page.title}
          />
        ))}
      </div>
    </Layout>
  )

  function searchPages(value) {
    if (!value || value.length < 3) {
      cleanSearchResults()
      return
    }

    setProductsResults(searchIndexesByQuery(productsSearchIndex, value))
    setProductGroupsResults(
      searchIndexesByQuery(productGroupsSearchIndex, value)
    )
    setPagesResults(searchIndexesByQuery(pagesSearchIndex, value))
  }

  function cleanSearchResults() {
    setProductsResults([])
    setPagesResults([])
  }

  function searchIndexesByQuery(searchIndex, query) {
    const lowerCaseQuery = query.toLowerCase()

    return searchIndex
      .map((item) => ({
        ...item,
        match: item.textEntries.find((entry) =>
          entry.toLowerCase().includes(lowerCaseQuery)
        ),
      }))
      .filter((item) => item.match)
  }

  function handleInputChange(event) {
    setQuery(event.target.value)
    updateHref(event.target.value)
  }

  function getSearchQueryFromUrl(url) {
    const queryParam = url.match(searchParamRegExp)
    return queryParam && queryParam[0] && decodeURI(queryParam[0].substr(3))
  }

  function getPageResultDescription(page) {
    return page.match !== page.title ? page.match : page.description
  }

  function updateHref(query) {
    const fullPath = query ? `${path}?q=${query}` : path
    navigate(fullPath, { replace: true })
  }
}

export const query = graphql`
  query SearchPage($locale: String) {
    ...LayoutFragment
    ...SearchPageFragment
    ...TranslationsFragment
  }
`
