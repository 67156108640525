import React from "react"

import LocalizedProductLink from "../common/LocalizedProductLink"
import s from "./PageSearchResult.module.scss"

export default function ProductGroupSearchResult({
  description,
  name,
  query,
  slug,
}) {
  const descriptionWithHighlight = description.replace(
    new RegExp(`(${query})`, "gmi"),
    "<b>$1</b>"
  )

  return (
    <LocalizedProductLink
      className={s.pageSearchResult}
      productGroupSlug={slug}
    >
      <h4 className={s.title}>{name}</h4>
      <p
        className={s.description}
        dangerouslySetInnerHTML={{ __html: descriptionWithHighlight }}
      />
    </LocalizedProductLink>
  )
}
