import React from "react"

import LocalizedLink from "../common/LocalizedLink"
import s from "./PageSearchResult.module.scss"

export default function PageSearchResult({
  description,
  pageId,
  title,
  query,
}) {
  const descriptionWithHighlight = description.replace(
    new RegExp(`(${query})`, "gmi"),
    "<b>$1</b>"
  )

  return (
    <LocalizedLink className={s.pageSearchResult} to={pageId}>
      <h4 className={s.title}>{title}</h4>
      <p
        className={s.description}
        dangerouslySetInnerHTML={{ __html: descriptionWithHighlight }}
      />
    </LocalizedLink>
  )
}
