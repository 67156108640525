import classNames from "classnames"
import React, { useContext } from "react"

import { formatNumber } from "../../utils/strings"
import { LocaleContext } from "../Layout"
import s from "./ProductShortInfo.module.scss"

export default function ProductShortInfo({ className, kcal, locale }) {
  const { ui } = useContext(LocaleContext)

  return (
    <div className={classNames(s.productShortInfo, className)}>
      {kcal ? (
        <>
          <span className={s.infoText}>
            {formatKcal(kcal)}
            {ui.kcal}
          </span>
        </>
      ) : null}
    </div>
  )

  function formatKcal(kcal) {
    return formatNumber(kcal, locale)
  }
}
